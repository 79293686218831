import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { User } from '../models/user';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable()
export class ChatService extends GenericService {

  constructor(
    private db: AngularFireDatabase,
    private dbStore: AngularFirestore,
  ) {
    super();
  }

  sendPrivateMessage(senderUser: User, currentMessage: string, chatId: string, chatName: string) {
    const messageData = {
      user_id: senderUser.user_id,
      messageBody: currentMessage,
      first_name: senderUser.first_name,
      last_name: senderUser.last_name,
      timeStamp: new Date().getTime()
    };
    if (currentMessage !== '') {
      this.db.list('chat/' + chatId + '/participants_chat/chats/' + chatName).push(messageData);
    }
  }

  getAllMessages(chatId: string, chatName: string) {
    return this.db.list('chat/' + chatId + '/participants_chat/chats/' + chatName).snapshotChanges()
  }

  saveHistory(chatId: string, senderUser: User, receiverUser: User, unreadMessages, hasUnreadMessages) {
    const userData = {
      user_id: receiverUser.user_id,
      first_name: receiverUser.first_name,
      last_name: receiverUser.last_name,
      unreadMessages: unreadMessages,
      hasUnreadMessages: hasUnreadMessages
    };
    this.db.list('chat/' + chatId + '/participants_chat/chat_history/' + senderUser.user_id).push(userData);
  }

  updateUserHistoryPrivateChat(chatId: string, key: string, updates, user_id: number) {
    return this.db.list('chat/' + chatId + '/participants_chat/chat_history/' + user_id).update(key, updates);
  }

  getHistory(chatId: string, userId: number) {
    return this.db.list('chat/' + chatId + '/participants_chat/chat_history/' + userId).snapshotChanges();
  }

  getALLHistory(chatId: string) {
    return this.db.list('chat/' + chatId + '/participants_chat/chat_history').snapshotChanges()
  }

  getUsersData(chatId) {
    return this.db.object('chat/' + chatId + '/users_data').valueChanges();
  }

  addUsersData(user: User, chatId: string, unreadMessages, privilegeId) {
    const userData = {
      userId: user.user_id,
      userName: user.first_name + ' ' + user.last_name,
      unreadMessages: unreadMessages,
      privilegeId: privilegeId,
      status: 'online',
      nbSession: 0
    };
    this.db.list('chat/' + chatId + '/users_data').push(userData);
  }
}
