import { StorageService } from 'src/app/shared/services/storage.service';
import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user';

declare var jQuery: any;

@Component({
  selector: 'app-user-qr-code-modal',
  templateUrl: './user-qr-code-modal.component.html',
  styleUrls: ['./user-qr-code-modal.component.css']
})
export class UserQrCodeModalComponent implements OnInit {
  user: User;

  constructor(private storageService: StorageService) { 
    this.user = this.storageService.read(StorageService.USER_KEY);
  }

  ngOnInit(): void {
  }

  closeModal() {
    jQuery('#userQRCodeModal').modal('hide');
  }

}
