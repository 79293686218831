<div [ngBusy]="busy" class="bubble" *ngIf="userReciever?.user_id"
    routerLink="/{{congressId}}/chat/{{userReciever?.user_id}}">
    <a class="d-flex" [ngClass]="{ 'pb-3' : !isLast}">
        <img class=" rounded-circle shadow-l" width="50" style="margin-right: 10px; margin-left: 10px;"
            *ngIf="userReciever.profile_img" src="{{filesUrl + userReciever.profile_img.path}}">
        <div class="avatar rounded-circle shadow-l align-self-center" *ngIf="!userReciever.profile_img"
            style="margin-right: 10px; margin-left: 10px;" [style.display]="isSearch ? 'margin-top : 10px' : 'margin-top: 0px ;'">
            <p >{{receiverFirstLetters}}</p>
        </div>
        <div class="align-self-center">
            <p class="font-14 font-600 color-theme mb-0 line-height-s" *ngIf="userReciever">{{userReciever.first_name}}
                {{userReciever.last_name}}</p>
            <p class="font-11 mb-0 line-height-s name-overflow" *ngIf="lastMessage">{{lastMessage.messageBody}}</p>
        </div>
        <div [style.display]="isSearch ? 'none' : 'block'" class="position-absolute end-0 pe-3">
            <span class="font-9 opacity-40 color-theme">{{lastMessageTimeStamp}}</span><br>
            <span
                class="float-end mt-n1 pt-1 badge rounded-pill bg-blue-dark font-9 font-400 scale-switch">{{nbunreadMessages}}</span>
        </div>
    </a>
    <div class="divider mb-0" *ngIf="!isLast"></div>
</div>