import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Stand } from '../../models/stand';
import { User } from '../../models/user';
import { StandService } from '../../services/stand.service';
import { StorageService } from '../../services/storage.service';
import { UserService } from '../../services/user.service';
import { Utils } from '../../utils';

@Component({
  selector: 'app-default-users',
  templateUrl: './default-users.component.html',
  styleUrls: ['./default-users.component.css']
})
export class DefaultUsersComponent implements OnInit {
  search: string = '';
  page: number = 1;
  perPage: number = 6;
  users: User [] = [];
  usersPage: number = 1;
  congressId: string;
  stands: Stand[] = [];
  filesUrl = environment.filesUrl;
  @Input() typeData: string;
  @Input() withStand: boolean = true;
  @Input() selectedCountry: string = '';
 
  
  constructor(private standService: StandService,
    private storageService: StorageService,
    private userService: UserService) {
    this.congressId = this.storageService.read(StorageService.CONGRESS_ID);
  }

  ngOnInit(): void {
    this.typeData == 'user' ? this.getAllUsers() : this.getAllStands();
  }

  getAllUsers() {
    this.userService.getRandomUsersByCongress(this.congressId, this.selectedCountry).subscribe((data: any) => {   
      this.users = data;
      for (let user of this.users) {
        user.first_name = Utils.capitalizeFirstLetters(user.first_name);
      }
    });
  }

  getAllStands() {
    this.standService.getRandomStands(this.congressId).subscribe((data: any) => {
       this.stands= data;
    });
  }

  firstLetters(user) { 
    return Utils.getUserFirstLetters(user); 
  }
}
