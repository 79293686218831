
<div class="content">
    <!-- List of Default Users -->
    <div class="row mb-0 mx-auto" *ngIf="typeData=='user'">
        <a *ngFor="let user of users" routerLink="/{{congressId}}/user-profile/{{user?.user_id}}" class="col-3">
            <img width="60" class="rounded-xl avatar shadow-l" *ngIf="user?.profile_img" src="{{filesUrl + user?.profile_img?.path}}">
            <div class=" text-center avatar shadow-l" *ngIf="!user?.profile_img">
                <p>{{firstLetters(user)}}</p>
            </div>
            <p class="font-600 color-theme name-overflow mx-2" [ngClass]="{ 'name-center': user?.first_name.length <= 5 }">{{user.first_name}}</p>
        </a>
    </div>
    <!-- List of Default Stands -->
   
 <div class="row mb-0 mx-auto" *ngIf="typeData=='stand'">
    <a *ngFor="let stand of stands"  routerLink="/{{stand.congress_id}}/details/{{stand.stand_id}}" class="col-3">
            <img class="rounded-xl avatar shadow-l" *ngIf="stand?.organization.logo" src="{{filesUrl + stand?.organization?.logo}}">
            <div class=" text-center avatar shadow-l" *ngIf="!stand?.organization.logo">
                <p style="font-size: xx-small">{{ 'Exhibitor' | translate}}</p>
            </div>
            <p class="font-600 color-theme name-overflow mx-2" [ngClass]="{ 'name-center': stand?.name.length <= 5 }">{{stand.name}}</p>
        </a>
    </div>
</div>
  

