import { Component, Input, OnInit } from '@angular/core';
import { MeetingTable } from '../../models/meetingTable';
declare var jQuery: any;

@Component({
  selector: 'app-item-table',
  templateUrl: './item-table.component.html',
  styleUrls: ['./item-table.component.css']
})
export class ItemTableComponent implements OnInit {
  @Input() meetingTable: MeetingTable;
  constructor() { }

  ngOnInit(): void {
  }
  
  showMeetingModal(userId) {
    jQuery('#' + userId).modal('show');
  }
}
