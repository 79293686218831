import { ItemStandComponent } from './shared-components/item-stand/item-stand.component';
import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgBusyModule } from 'ng-busy';
import localeFr from '@angular/common/locales/fr';
import { FormsModule } from '@angular/forms';
import { SearchComponent } from './shared-components/search/search.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ErrorMessageComponent } from './shared-components/error-message/error-message.component';
import { RouterModule } from '@angular/router';
import { ScheduleMeetingComponent } from './shared-components/schedule-meeting/schedule-meeting.component';
import { ItemUserComponent } from './shared-components/item-user/item-user.component';
import { SideBarComponent } from './shared-components/side-bar/side-bar.component';
import { UserQrCodeModalComponent } from './shared-components/user-qr-code-modal/user-qr-code-modal.component';
import { QrCodeComponent } from './shared-components/qr-code/qr-code.component';
import { ItemUserHistoryComponent } from './shared-components/item-user-history/item-user-history.component';
import { DefaultUsersComponent } from './shared-components/default-users/default-users.component';
import { StarsRatingComponent } from './shared-components/stars-rating/stars-rating.component';
import { ItemTableComponent } from './shared-components/item-table/item-table.component';
import { NothingFoundComponent } from './shared-components/nothing-found/nothing-found.component';
import { ItemAbstractComponent } from './shared-components/item-abstract/item-abstract.component';
import { ItemSponsorComponent } from './shared-components/item-sponsor/item-sponsor.component';
import { ItemAccessComponent } from './shared-components/item-access/item-access.component';
import { ItemSpeakerComponent } from './shared-components/item-speaker/item-speaker.component';
import { NgSelectModule } from '@ng-select/ng-select';


registerLocaleData(localeFr);


@NgModule({
  declarations: [
    SearchComponent,
    ItemStandComponent,
    ErrorMessageComponent,
    ItemUserComponent,
    SideBarComponent,
    UserQrCodeModalComponent ,
    QrCodeComponent  ,
    ScheduleMeetingComponent,   
    ItemUserComponent, 
    DefaultUsersComponent, 
    StarsRatingComponent, 
    ItemUserHistoryComponent,
    ItemUserComponent,
    DefaultUsersComponent,
    ItemTableComponent,
    NothingFoundComponent,
    ItemAbstractComponent,  
    ItemSponsorComponent,
    ItemAccessComponent,
    ItemSpeakerComponent,
  ],
  imports: [
    CommonModule,
    NgBusyModule,
    TranslateModule,
    FormsModule,
    InfiniteScrollModule,
    RouterModule,
    NgSelectModule
  ],
  exports: [
    CommonModule,
    NgBusyModule,
    FormsModule,
    TranslateModule,
    SearchComponent,
    ItemStandComponent ,
    ErrorMessageComponent,
    ItemStandComponent ,
    TranslateModule,
    SideBarComponent,
    ItemUserComponent,
    UserQrCodeModalComponent,
    QrCodeComponent,
    ScheduleMeetingComponent,
    DefaultUsersComponent,
    StarsRatingComponent,    
    ItemUserHistoryComponent,
    ItemTableComponent,
    NothingFoundComponent,
    ItemAbstractComponent,
    ItemSponsorComponent,
    ItemAccessComponent,
    ItemSpeakerComponent,  
    ItemAccessComponent

  ]
})
export class SharedModule { }
