import { StorageService } from 'src/app/shared/services/storage.service';
import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SubmissionService extends GenericService {
  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }

  getSubmissionByQrCode(qr_code) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + `/project/qrcode?qr_code=` + qr_code;
    return this.http.get(url, { headers: headers });


  }
  calculeUserScore(congressId, responses) {
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + `/submission/` + congressId + `/quiz-responses`;
    return this.http.post(url, { responses: responses }, { headers: headers });
  }

  getQuizAbstractUserScoreByUserId(congressId, qrCode){
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + `/submission/` + congressId + `/quiz-abstract-score?qr_code=` + qrCode;
    return this.http.get(url, { headers: headers });

  }

}

