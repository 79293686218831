import { Congress } from 'src/app/shared/models/congress';
import { StorageService } from './../../shared/services/storage.service';
import { StandService } from 'src/app/shared/services/stand.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PlanStand } from 'src/app/shared/models/planstand';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-plan-stands',
  templateUrl: './plan-stands.component.html',
  styleUrls: ['./plan-stands.component.css']
})
export class PlanStandsComponent implements OnInit {
  congressId: string;
  congress: Congress = new Congress();
  busy: Subscription;
  url: any;
  filesUrl = environment.filesUrl
  imgPlanStands: PlanStand[] = [];


  constructor(
    private storageService: StorageService,
    private standService: StandService,
    private sharedService: SharedService,
    private route: ActivatedRoute) {
    this.congressId = this.storageService.read(StorageService.CONGRESS_ID);

  }

  ngOnInit(): void {
    this.sharedService.setTitle('PlanStands');
    this.getImgPlanStand(this.congressId)
  }

  getImgPlanStand(congressId) {
    this.busy = this.standService.getPlanStands(congressId).subscribe((data: PlanStand[]) => {
      this.imgPlanStands = data;

    })
  }

}
