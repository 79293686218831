<div class="d-flex">
    <div>
        <img *ngIf="user?.profile_img" src="{{filesUrl + user?.profile_img?.path}}"
            class="me-3 mb-3 rounded-circle shadow-l" width="50">
        <div class="avatar me-3 mb-3 rounded-circle shadow-l" *ngIf="!user?.profile_img"> 
            <p>{{userFirstLetters}}</p>
        </div>
    </div>
    <div>
        <h5 class="mt-1 mb-0"> {{user?.first_name}} {{user?.last_name}} </h5>
        <div *ngIf="user.user_congresses[0].chat_info">
            <div *ngFor="let info of chatInfo; let i=index; let last=last;">
                <p class="font-10 mt-n1 color-eventizer" [ngStyle]="{'margin-bottom': i== last ? '0px': ''}">
                    {{info}}
                </p>
            </div>
        </div>
    </div>
    <div class="ms-auto"><span class="slider-next badge bg-highlight mt-2 p-2 font-8" routerLink="/{{congressId}}/user-profile/{{user.user_id}}"> {{'TapForMore' | translate}}
        </span>
    </div>
</div>