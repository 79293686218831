<div class="header header-fixed header-logo-center">
	<a class="header-title"> {{title | translate}} </a>
	<a (click)="goBackLastPage()" data-back-button class="header-icon header-icon-1">
		<i class="fas " [ngClass]="{'fa-arrow-left' : lang != 'ar',
		'fa-arrow-right' : lang == 'ar'}"></i>
	</a>
	
	<div class="d-flex justify-content-between header-icon header-icon-4"
	[ngStyle]="{ 'margin-right': lang != 'ar' ? '0px' : '', 'margin-left': lang != 'ar' ? '' : '0px' }">
		<div class="col-4" style="margin-top: 4px;margin-left: 10px;" *ngIf="congress?.config?.show_interactions==1">
			<a routerLink="chat-history">
				<i *ngIf="haveUnreadMessages" class="font-10 fa fa-circle color-theme"></i>
				<i class="font-16 fa fa-comment color-theme"></i>
				
			</a>
		</div>	
		<div class="col-4" *ngIf="!isLoginPage" style="margin-top: 4px;margin-left: 10px;" (click)="goToAction()">
			<a>
				<i class="font-16 fa fa-cog fa-xl color-theme" (click)="goToAction()"></i>
			</a>
		</div>
		<div class="col-4" *ngIf="!isLoginPage" style="margin-top: 4px;margin-left: 10px;">
			<a (click)="OnSubmit()"
				[ngStyle]="{'margin-left': lang != 'ar' ? '-10%' : '0%', 'margin-top': lang != 'ar' ? '70%' : '10%' }">
				<i  class=" font-16 fa fa-qrcode"></i>
			</a>
		</div>	
		<div class="col-4" style="margin-left: 10px;">
			<div class="input-style input-style-always-active no-borders no-icon">
				<select id="form5a" style="margin-top: 3px;" 
				(change)="saveLanguage($event)" [(ngModel)]="lang">
					<option value="fr">FR</option>
					<option value="en">EN</option>
					<option value="ar">AR</option>
				</select>
			</div>
		</div>
		
	</div>
</div>
<router-outlet></router-outlet>
