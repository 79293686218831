import { Component, EventEmitter, OnInit, Output } from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'app-stars-rating',
  templateUrl: './stars-rating.component.html',
  styleUrls: ['./stars-rating.component.css']
})
export class StarsRatingComponent implements OnInit {

  @Output() rating: EventEmitter<number> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  addRate(i, event) {
    this.rating.emit(i);
    if (!jQuery('#' + i).hasClass('color-yellow-dark')) {
      for (let j = 1; j <= i; j++) {
        jQuery('#' + j).removeClass('custom-color');
        jQuery('#' + j).addClass('color-yellow-dark');
      }
    } else {
      for (let j = 5; j > i; j--) {
        jQuery('#' + j).removeClass('color-yellow-dark');
        jQuery('#' + j).addClass('custom-color');
      }
    }
  }

}
