<div class="mt-5">
    <div class="card">
        <div class="mt-5">
            <div [hidden]="!hasCameras">
                <zxing-scanner #scanner start="true"
                    (scanSuccess)="handleQrCodeResult($event)" cssClass="big-video"></zxing-scanner>
            </div>
            <div *ngIf="!hasCameras && hasPermission === true">
                <p class="first-description m-4"> {{'NoCamera' | translate }}</p>
            </div>
            <div *ngIf="hasPermission === undefined">
                <p class="first-description m-4"> {{'Waiting' | translate }} </p>
            </div>
            <div *ngIf="hasPermission === false">
                <p class="first-description m-4"> {{'CameraDenied' | translate }} </p>
            </div>
        </div>
    </div>
</div>
<app-error-message [message]="message"></app-error-message>