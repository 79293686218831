import { User } from './../../models/user';
import { Component, Input, OnInit } from '@angular/core';
import { Utils } from '../../utils';
import { environment } from 'src/environments/environment';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-item-speaker',
  templateUrl: './item-speaker.component.html',
  styleUrls: ['./item-speaker.component.css']
})
export class ItemSpeakerComponent implements OnInit {
  @Input() accessSpeaker: User = new User();
  @Input() isChair: boolean = false;
  congressId: string;
  filesUrl = environment.filesUrl;
  constructor(private storageService: StorageService,) { 
    this.congressId = this.storageService.read(StorageService.CONGRESS_ID);
  }

  ngOnInit(): void {
  }
}

