import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/shared/models/user';
import { ChatService } from 'src/app/shared/services/chat.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Utils } from 'src/app/shared/utils';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
  chatId: string;
  userSender: User = new User();
  userRecieverIds: number[] = [];
  congressId: string;
  isSearch: boolean;

  constructor(private sharedService: SharedService,
    private chatService: ChatService,
    private storageService: StorageService,
    private router: Router) { }

  ngOnInit(): void {
    this.sharedService.setTitle('ChatHistory');
    this.userSender = this.storageService.read(StorageService.USER_KEY);
    if (!this.userSender) {
      this.router.navigate(['/auth/login']);
    }
    this.congressId = this.storageService.read(StorageService.CONGRESS_ID);
    this.chatId = Utils.getRoomName(this.congressId);
    this.getAllHistoryParticipants();
  }

  getAllHistoryParticipants() {
    this.chatService.getHistory(this.chatId, this.userSender.user_id).subscribe((actions: any[]) => {
      this.filterHistory(actions);
    });
  }

  filterHistory(actions) {
    this.userRecieverIds = [];
    actions.forEach(action => {
      this.userRecieverIds.push(action.payload.val().user_id);
      return;
    });
  }

  handleIsSearch(event) {
    this.isSearch = event;
  }

}
