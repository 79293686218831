import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.css']
})
export class ErrorMessageComponent implements OnInit {
  @Input() type: string = 'Error';
  @Input() message: string;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
  @Input() modalId: string = 'errorMessage';
  @Output() confirm: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  closeMenu() {
    jQuery('#' + this.modalId).modal('hide');
    this.closeModal.emit(true);
  }

  confirmCancel(e: boolean) {
    this.confirm.emit(e);
    if(!e){
      jQuery('#' + this.modalId).modal('hide');
    } 
  }
}
