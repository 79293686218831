import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Sponsor } from '../../models/Sponsor';

@Component({
  selector: 'app-item-sponsor',
  templateUrl: './item-sponsor.component.html',
  styleUrls: ['./item-sponsor.component.css']
})
export class ItemSponsorComponent implements OnInit {
  @Input() sponsor: Sponsor;
  @Input() isLast: boolean;
  filesUrl= environment.filesUrl;
  constructor() { }

  ngOnInit(): void {
  }

}
