import { Country } from './../models/Country';
import {Injectable} from '@angular/core';

@Injectable()
export class StorageService {
  static USER_KEY: string = 'user';
  static USER_TOKEN_KEY: string = 'user-token';
  static CONGRESS_ID: string = 'event-id';
  static CONGRESS: string = 'event'
  static LANGUAGE: string = 'lang';
  static URL: string = 'url';
  static URLPARAMS: string = 'url-params';
  static SearchValue: string = 'search-valeu';
  static Country: string = 'country';
  static Question: string = 'question';
  static CONFIGLANDINGPAGE: string = 'configLandingPage';
  
  write(key: string, value: any) {
    if (value) {
      this.remove(key);
      value = JSON.stringify(value);
      localStorage.setItem(key, value);
    }
  }

  read<T>(key: string): T {
    const value: string = localStorage.getItem(key);

    if (value && value !== 'undefined' && value !== 'null') {
      return <T>JSON.parse(value);
    }

    return null;
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  removeAll() {
    localStorage.clear();
  }

  isExist(key: string) {
    return localStorage.getItem(key) != null;
  }
}
