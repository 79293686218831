import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.css']
})
export class QrCodeComponent implements OnInit {
  @Input()  userLoggedQrCode: string;
  filesUrl = environment.filesUrl;
  constructor() { }

  ngOnInit(): void {
  }

}
