

<div class="card card-style" style="display: flex !important;">
    <h2 class="cut-text p-2 ms-2" [ngStyle]="{'color': access.title_color != null ? access.title_color : 'black'}">{{access.name}}</h2>
    <div class="d-flex">
        <div class="d-flex flex-column">
            <h3 class="color-black font-12 opacity-70 mt-1 mb-1">
                <i class="ms-3 far fa-clock"></i>
                {{ getAccesseTime(access.start_date)}}
            </h3>
            <h3 class="color-black font-12 opacity-70 mt-1 mb-1">
                <i class="ms-3 far fa-clock"></i>
                {{' ' + getAccesseTime(access.end_date)}}
            </h3>
        </div>
        <div class="ms-auto me-3 my-auto">
            <a routerLink="/{{congressId}}/list-details/{{access.access_id}}" href="javascript:void"
                class="float-end bg-highlight btn btn-xs text-uppercase font-900 rounded-xl">{{'Details' | translate}} </a>
        </div>
    </div>
</div>