import { UserService } from './../../services/user.service';
import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Congress } from '../../models/congress';
import { Meeting } from '../../models/meeting';
import { MeetingService } from '../../services/meeting.service';
import { Utils } from '../../utils';
import { MeetingDates, MeetingsGroupedByTime } from '../../models/MeetingDates';
declare var jQuery: any;
import { groupBy } from "lodash-es";
import { User } from '../../models/user';


@Component({
  selector: 'app-schedule-meeting',
  templateUrl: './schedule-meeting.component.html',
  styleUrls: ['./schedule-meeting.component.css']
})
export class ScheduleMeetingComponent implements OnInit, AfterViewInit {
  modal_id: number;
  meeting: Meeting = new Meeting();
  meetingModel: MeetingModel = new MeetingModel();
  message: string;
  type: string;
  @Input() congress: Congress = new Congress();
  @Input() receiver_id: number;
  submitted: boolean = false;
  busy: Subscription;
  startHour: string;
  endHour: string;
  errorMessageId: string;
  @Output() closeErrorModal: EventEmitter<boolean> = new EventEmitter();
  @Input() meetingsDate: MeetingDates[]=[];
  timesGroups: MeetingsGroupedByTime[]=[]
  eventsDay: string[]=[]
  meetingsTimes = [];
  userBusy: Subscription;
  @Input() user: User;
  userDates = [];
  userTimesGroups = [];
  userReady: boolean = false;
  disabledTime: boolean[] = [];
  @Input() sendMeetingsDate: boolean = false;
  url : string ;

  constructor(private meetingService: MeetingService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.startHour = Utils.getHourByDate(this.congress.start_date);
    this.endHour   = Utils.getHourByDate(this.congress.end_date);
    this.modal_id = this.receiver_id;
    this.errorMessageId = 'error' + this.receiver_id;
  //  this.getUserDetails();
    this.getCongressDates();
    this.getMeetingDates(this.congress.congress_id);
    this.url = window.location.href.substring(0, window.location.href.indexOf("#") + 2);
  }

    ngAfterViewInit(): void {
    }

  isEmpty() {
    return !this.meeting.start_date || !this.meeting.end_date || !this.meetingModel.timeStart;
  }

  send() {
    this.meetingModel.datetimeStart = Utils.concatenation(this.meetingModel.dateStart, this.meetingModel.timeStart);
    this.meeting.start_date = this.meetingModel.datetimeStart;
    this.meetingModel.datetimeEnd = Utils.concatenation(this.meetingModel.dateStart, this.meetingModel.timeEnd);
    this.meeting.end_date = this.meetingModel.datetimeEnd;
    this.submitted = true;
    if (this.isEmpty()) {
      this.type = 'Error';
      this.message = 'EmptyMeetingFields';
      jQuery('#' + this.errorMessageId).modal('show');
      return;
    }
    if (this.dateValidation()) {
      this.type = 'Error';
      this.message = 'DateError';
      jQuery('#' + this.errorMessageId).modal('show');
      return;
    }
    jQuery('#sendRequest' + this.modal_id).prop('disabled', true);
    this.busy = this.meetingService.requestMeeting(this.meeting, this.receiver_id, this.congress.congress_id, this.url)
      .subscribe(data => {
        this.meeting = new Meeting();
        this.meetingModel = new MeetingModel();
        this.message = 'SucessMessage';
        this.type = 'Success';
       this.getMeetingDates(this.congress.congress_id)
        jQuery('#' + this.errorMessageId).modal('show');
        this.closemodal();
        jQuery('#sendRequest' + this.modal_id).prop('disabled', false);
      },
        error => {
          this.closemodal();
          this.type = 'Error';
          if (error.status === 402) {
            jQuery('#sendRequest' + this.modal_id).prop('disabled', false);
            this.message = 'HasSameMeeting';
            jQuery('#' + this.errorMessageId).modal('show');
            return;
          } else if (error.status === 400) {
            jQuery('#sendRequest' + this.modal_id).prop('disabled', false);
            this.message = 'DateError';
            jQuery('#' + this.errorMessageId).modal('show');
            return;
          } else if (error.status === 401) {
            jQuery('#sendRequest' + this.modal_id).prop('disabled', false);
            this.message = 'UserNotFound';
            jQuery('#' + this.errorMessageId).modal('show');
            return;
          }  else if (error.status === 406) {
            jQuery('#sendRequest' + this.modal_id).prop('disabled', false);
            this.message = 'InvalidDates';
            jQuery('#' + this.errorMessageId).modal('show');
          } else {
          this.message = 'TryAgain';
          jQuery('#' + this.errorMessageId).modal('show');
          return;
          }
        });
  }

  closemodal() {
    jQuery('#' + this.modal_id).modal('hide');
  }

  dateValidation() {
    return !this.meetingModel.congressAvailableDates.includes(this.meetingModel.dateStart);   
  }

  setEndTime(e) {
    this.meetingModel.timeEnd = Utils.addDudation(this.meetingModel.timeStart, this.congress.config.meeting_duration);
  }

  setEndDate(e) {
    this.meetingModel.dateEnd = this.meetingModel.dateStart;
    if (this.meetingsDate.length > 0) {
      for (let time of this.meetingsTimes) {
        if (time.date == this.meetingModel.dateStart) {
          this.meetingModel.congessAvailableMeetingTimes = time.times;
          this.disabledTime = time.disabledTime;
          this.meetingModel.timeStart = null;
        }
      }
    }
  }

  getCongressDates() {
    this.meetingModel.congressAvailableDates = Utils.getAvailableDates(this.congress);
  }

  colseErrorMessageModal() {
    if (this.message === 'SucessMessage') {
      this.closeErrorModal.emit(true);
    }
  }

  getMeetingDates(congress_id) {
    if (!this.sendMeetingsDate) {
      this.busy = this.meetingService.getMeetingDates(congress_id).subscribe((data: any) => {
        this.meetingsDate = data;
        this.calculateTimeslots();
      });
    } else {
      this.calculateTimeslots();
    }
  }

  calculateTimeslots() {
    if (this.meetingsDate.length == 0) {
      // Get timeslots when no Meeting dates are configured
      for (let date of this.meetingModel.congressAvailableDates) {
        this.meetingModel.congessAvailableMeetingTimes = Utils.getDurationTimes(this.congress.config.meeting_duration, this.congress.config.pause_duration, this.startHour, this.endHour);
        this.meetingsTimes.push({
          date: date,
          times: this.meetingModel.congessAvailableMeetingTimes,
          disabledTime: this.setAllDisabledToTrue(this.meetingModel.congessAvailableMeetingTimes)
        });
      }
    } else {
      this.meetingsDate.forEach(e => {
        e.startHour = Utils.getHourByDate(e.start_date);
        e.endHour   = Utils.getHourByDate(e.end_date);
        e.sDate     = Utils.getDate(e.start_date);
      });
      this.groupDates();
      let dateExist;
      let timeGroup;
      for (let i = 0; i < this.meetingModel.congressAvailableDates.length; i++) {
        dateExist = false;
        timeGroup = null;
        for (let j = 0; j < this.timesGroups.length; j++) {
          if (this.timesGroups[j].start_date == this.meetingModel.congressAvailableDates[i]) {
            dateExist = true;
            timeGroup = this.timesGroups[j];
            break;
          }
        }
        if (!dateExist) {
          // Get timeslots when no Meeting date is configured for this specific date
          this.meetingModel.congessAvailableMeetingTimes = Utils.getDurationTimes(this.congress.config.meeting_duration, this.congress.config.pause_duration, '00:00', '00:00');
          this.meetingsTimes.push({
            date: this.meetingModel.congressAvailableDates[i],
            times: this.meetingModel.congessAvailableMeetingTimes,
            disabledTime: this.setAllDisabledToTrue(this.meetingModel.congessAvailableMeetingTimes)
          });
        } else {
          // Get timeslots for an only one configured meeting date
          if (timeGroup.dates.length == 1) {
            this.meetingModel.congessAvailableMeetingTimes = Utils.getDurationTimes(this.congress.config.meeting_duration, this.congress.config.pause_duration, timeGroup.dates[0].startHour, timeGroup.dates[0].endHour);
            this.meetingsTimes.push({
              date: this.meetingModel.congressAvailableDates[i],
              times: this.meetingModel.congessAvailableMeetingTimes,
              disabledTime: this.setAllDisabledToTrue(this.meetingModel.congessAvailableMeetingTimes)
            });
          } else {
            // Get timeslots for multiple configured meeting dates
            let finalDates = [];
            let result = [];
            for (let i = 0; i < timeGroup.dates.length; i++) {
              finalDates[i] = Utils.getDurationTimes(this.congress.config.meeting_duration, this.congress.config.pause_duration, timeGroup.dates[i].startHour, timeGroup.dates[i].endHour);
            }
            for (let date of finalDates) {
              result.push.apply(result, date);
            }
            this.meetingsTimes.push({
              date: this.meetingModel.congressAvailableDates[i],
              times: result,
              disabledTime: this.setAllDisabledToTrue(result)
            });
          }
        }
      }
    }
    this.getUserDetails();
  }

  groupDates() {
    const baseContext = this;
    var grouped = groupBy(baseContext.meetingsDate,
      function (date) {
        return date.sDate ? date.sDate : null;
      });
    var dates = Object.keys(grouped);
    for (let i = 0; i < dates.length; i++) {
      this.timesGroups.push(
        {
          start_date: dates[i] != "null" ? dates[i] : null,
          dates: grouped[dates[i]]
        }
      );
    }
  }

  getUserDetails() {
    if (!this.user) {
      this.userBusy = this.userService.getResponseUserInformations(this.receiver_id, this.congress.congress_id).subscribe((data: User) => {
        this.user = data;
        this.userReady = true;
        this.getUserReservedMeetings(this.user)
        setTimeout(() => {
          this.getFinalDatesResults();
        }, 2000);
      });
    } else {
      this.userReady = true;
      this.getUserReservedMeetings(this.user)
      setTimeout(() => {
        this.getFinalDatesResults();
      }, 2000);
    }
  }

  getUserReservedMeetings(user) {
    if (user.meetings_organizer.length > 0 || user.meetings_participant.length > 0) {
      this.userDates = user.meetings_organizer.concat(user.meetings_participant);
      this.formtUserDates();
      this.groupUserDates();
    }
  }

  formtUserDates() {
    this.userDates.forEach(e => {
      e.startHour = Utils.getHourByDate(e.start_date);
      e.endHour   = Utils.getHourByDate(e.end_date);
      e.sDate     = Utils.getDate(e.start_date);
    });
  }

  groupUserDates() {
    var grouped = groupBy(this.userDates,
      function (date) {
        return date.sDate ? date.sDate : null;
      });
    var dates = Object.keys(grouped);
    for (let i = 0; i < dates.length; i++) {
      if (grouped[dates[i]].length == 1) {
        this.userTimesGroups.push(
          {
            date: dates[i] != "null" ? dates[i] : null,
            times: [grouped[dates[i]][0]['startHour']]
          });
      } else {
        let startHours = [];
        for (let date of grouped[dates[i]]) {
          startHours.push(date['startHour']);
        }
        this.userTimesGroups.push(
          {
            date: dates[i] != "null" ? dates[i] : null,
            times: startHours
          });
      }
    }
  }

  setAllDisabledToTrue(results) {
    let disabledTimes = []
    for (let result of results) {
      disabledTimes.push(false);
    }
    return disabledTimes;
  }

  getFinalDatesResults() {
    if (this.userReady && this.userTimesGroups.length > 0) {
      for (let time of this.meetingsTimes) {
        for (let userTime of this.userTimesGroups) {
          if (time.date == userTime.date) {
            for (let startTime of userTime.times) {
              const index = time.times.indexOf(startTime);
              if (index > -1) {
                time.disabledTime[index] = true;
              }
            }
          }
        }
      }
    }
  }

}

export class MeetingModel {
  dateStart: string = null;
  dateEnd: string = null;
  timeStart: string = null;
  timeEnd: string = null;
  datetimeStart: any = null;
  datetimeEnd: any = null;
  congressAvailableDates: any = [];
  congessAvailableMeetingTimes: any = [];
}
