<div class="modal fade" id="userQRCodeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="menu-title error p-2">
				<div class="text-center">
					<h1 style="margin-right: 13.75px;"> {{'MyQRCode' | translate }} </h1>
				</div>
				<a (click)="closeModal()" class="close-menu color-eventizer">
					<i class="fa fa-times"></i>
				</a>
			</div>
			<div class="divider divider-margins mb-n2"></div>
			<div class="modal-body">
				<app-qr-code [userLoggedQrCode]="user.qr_code"></app-qr-code>
			</div>
		</div>
	</div>
</div>