import { Congress } from 'src/app/shared/models/congress';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { StorageService } from './../../services/storage.service';
import { User } from 'src/app/shared/models/user';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { UserService } from '../../services/user.service';

declare var jQuery: any;

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {
  user: User;
  filesUrl = environment.filesUrl;
  congressId: number;
  language: string;
  congress: Congress = new Congress(); 

  constructor(private storageService: StorageService, 
    private router: Router,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private userService: UserService) {
    this.user = this.storageService.read(StorageService.USER_KEY);
    this.congressId = this.storageService.read(StorageService.CONGRESS_ID);
  }

  ngOnInit(): void {
    this.congress = this.storageService.read(StorageService.CONGRESS);
    this.user.last_name = this.user.last_name.toUpperCase();
    this.user.first_name = Utils.capitalizeFirstLetters(this.user.first_name);
  }

  closeMenu() {
    let sidebar = document.getElementById('menu-sidebar-left-1');
    sidebar.classList.remove('menu-active');
    let menuHider = document.querySelectorAll('.menu-hider')[0];
    menuHider.classList.remove('menu-active');
  }

  darkMode() {
    Utils.changeMode();
  }

  showQRCode() {
    jQuery('#userQRCodeModal').modal('show');
    this.closeMenu();
  }

  logout() {
   this.userService.logout().subscribe(
      (data) => {
      },
      error => {
      }
    );
    this.storageService.remove(StorageService.USER_TOKEN_KEY);
    this.storageService.remove(StorageService.USER_KEY);
    this.router.navigateByUrl("auth/login");
    this.closeMenu();
  }
  
}
