<router-outlet></router-outlet>
<div id="footer-bar" class="footer-bar-1">
	<div class="mat-mini-fab-Profile" *ngIf="!isUser">
		<button mat-mini-fab color="wight" style="margin-bottom: 5px;" (click)="letScan()">
			<a class="fa fa-qrcode fa-2x"></a>
		</button>
	</div>
	<div class="mat-mini-fab-Profile" *ngIf="isUser && userCongresss && !isHistory">
		<!-- <div *ngIf="haveUnreadMessages" class="chat-dot"></div> -->
		<button *ngIf="userCongresss.privilege_id==2 && isCalendar" mat-mini-fab color="wight"
			style="margin-bottom: 5px;" (click)="qrCodeScan()">
			<a class="fa fa-qrcode fa-2x white-fa"></a>
		</button>
		<button *ngIf="!(userCongresss.privilege_id==2 && isCalendar)" mat-mini-fab color="wight"
			style="margin-bottom: 5px;" (click)="scanQrCodeForQuiz()">
			<a class="fa fa-qrcode fa-2x white-fa"></a>
		</button>
	</div>
	<a *ngFor="let pwamenu of pwamenus ; let i = index" routerLink="/{{congressId}}/{{pwamenu.page}}"
		routerLinkActive="active-nav" [routerLinkActiveOptions]="{exact: true}">
		<i class="{{pwamenu.icon}}"></i><span> {{pwamenu.label}}</span></a>
</div>
<app-side-bar *ngIf="isUser"></app-side-bar>

		