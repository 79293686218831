<a class="card-style" routerLink="/{{stand.congress_id}}/details/{{stand.stand_id}}">
    <div class="card-center px-3">
        <div  class="m-3"*ngIf="stand.stags && stand.stags.length>0" >
            <div *ngFor="let stag of stand.stags ; let i = index;" style="display: inline-block;">
                <span   class="badge bg-highlight text-uppercase color-white  px-2 py-1 m-1" *ngIf="i <3 && stag.color == null">
                    {{stag.label}}
                </span>
                
               <span class="badge text-uppercase color-white px-1 py-1 m-1"  *ngIf="i<3 && stag.color !== null" [style.background-color]="stag.color">
                    {{stag.label}}</span>
                
            </div>
            
            <span *ngIf="stand.stags.length >3" 
                class="badge bg-highlight text-uppercase color-white px-1 py-1 m-1 rounded-circle">+{{stand.stags.length-3}}</span>
        </div>
        <div>
        <h1  class="color-white font-20 black-borders" style="margin-top: 27px;"> {{stand.name}} </h1>
    </div>
    </div>
</a>