<div class="modal fade" id="{{modalId}}" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="menu-title error">
                <div class="text-center">
                    <h1 style="margin-right: 13.75px;"> {{type | translate}} </h1>
                </div>
                <a (click)="closeMenu()" class="close-menu" [ngStyle]="{'color':  type == 'Success' ? '#198754' : ''}">
                    <i class="fa fa-times"></i>
                </a>
            </div>
            <div class="divider divider-margins mb-n2"></div>
            <div class="modal-body">
                <div class="content">
                    <div class="list-group list-custom-large">
                        <a data-menu="menu-highlights mb-5">
                            <h6 class="mb-3" style="font-weight: 500;"> {{ message | translate }} </h6>
                        </a>
                    </div>
                </div>
                <div *ngIf="type == 'Attention'" class="sure-block">
                    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="confirmCancel(false)">{{ 'CANCEL' |
                        translate }} </button>
                    <button type="button" class="btn btn-success" style="margin-left: 12px;" (click)="confirmCancel(true)">{{ 'CONFIRM'
                        | translate }}</button>
                </div>
                <a *ngIf="type != 'Attention'" (click)="closeMenu()"
                    [ngClass]="{'btn-danger': type == 'error' || type == 'Error', 'btn-success': type == 'Success'}"
                    class="btn btn-full btn-m rounded-sm shadow-xl text-uppercase font-900">
                    {{'Close' | translate}}
                </a>
            </div>
        </div>
    </div>
</div>