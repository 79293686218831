import { GlobalSearchComponent } from './global-search/global-search.component';
import { HeaderBarComponent } from './header-bar/header-bar.component';
import { BottomNavigationComponent } from './bottom-navigation/bottom-navigation.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HistoryComponent } from './manage-chat/history/history.component';
import { QrCodeScanComponent } from './authentification/qr-code-scan/qr-code-scan.component';
import { PlanStandsComponent } from './manage-stands/plan-stands/plan-stands.component';




const routes: Routes = [
  {
    path: ':congressId/chat',
    children: [
      {
        path: '',
        loadChildren: () => import('./manage-chat/manage-chat.module').then(m => m.ManageChatModule)
      }
    ]
  },
  {
    path: 'auth',
    component: HeaderBarComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./authentification/authentification.module').then(m => m.AuthentificationModule)
      }
    ]
  },
  {
    path: ':congressId',
    component: HeaderBarComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./agora/agora.module').then(m => m.AgoraModule)
      }
    ]
  },
  {
    path: ':congressId',
    component: BottomNavigationComponent,
    children: [
      {
        path: '',
        component: HeaderBarComponent,
        children: [
          {
            path: '',
            loadChildren: () => import('./manage-stands/manage-stands.module').then(m => m.ManageStandsModule)
          },
          {
            path: '',
            loadChildren: () => import('./manage-meetings/manage-meetings.module').then(m => m.ManageMeetingsModule)
            
          },
          {
            path: '',
            loadChildren: () => import('./manage-user/manage-user.module').then(m => m.ManageUserModule)
          },
          {
            path: '',
            loadChildren: () => import('./program/program.module').then(m => m.ProgramModule)
          },
          {
            path: '',
            loadChildren: () => import('./manage-quiz/manage-quiz.module').then(m => m.ManageQuizModule)
          },
          {
            path: '',
            loadChildren: () => import('./attestation/attestation.module').then(m => m.AttestationModule)
          },
          {
            path: 'search',
            component: GlobalSearchComponent
          },
          {
            path: 'planstands',
            component: PlanStandsComponent
          },
          {
            path: 'search-B2B',
            component: GlobalSearchComponent
          },
          {
            path: 'chat-history',
            component:  HistoryComponent
          },
          {
            path: 'home',
            component: HomeComponent,
          },
          {
            path: 'qr-code',
            component: QrCodeScanComponent
          },
          {
            path: 'abstracts',
            loadChildren: () => import('./abstracts/abstracts.module').then(m => m.AbstractsModule)
          },
          { path: '',
            loadChildren: () => import('./manage-lp/manage-lp.module').then(m => m.ManageLpModule)
          }
          
        ]
      },
    ]
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
