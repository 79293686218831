
<div class="results-not-found card card-style shadow-l">
    <div class="content">
        <div class="search-no-results">
            <h3 class="bold top-10"> {{'NothingFound' | translate}} </h3>
            <span class="under-heading font-11 opacity-70 color-theme">
                {{'NothingMsg' | translate}}
            </span>
        </div>
    </div>
</div>
