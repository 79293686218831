import { Stand } from '../../models/stand';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-item-stand',
  templateUrl: './item-stand.component.html',
  styleUrls: ['./item-stand.component.css']
})
export class ItemStandComponent implements OnInit {
  @Input() stand: Stand;

  constructor() { }

  ngOnInit(): void {
  }

}
