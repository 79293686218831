<div id="menu-sidebar-left-1" class="bg-white menu menu-box-left" style="display: block; width: 310px;">
	<div class="d-flex justify-content-end border-bottom">
		<a (click)="closeMenu()" class="close-menu icon icon-m text-center color-red-dark">
			<i class="fa font-12 fa-times"></i>
		</a>
	</div>
	<div class="ps-3 pe-3 pt-3 mt-4 mb-2">
		<div class="d-flex">
			<div class="me-3">
				<img *ngIf="user.profile_img" src="{{filesUrl + user.profile_img.path}}" width="43">
			</div>
			<div class="flex-grow-1">
				<h1 class="font-22 font-700 mb-0"> {{user.first_name}} {{user.last_name}} </h1>
				<p class="mt-n2  font-10 font-400"> {{user.email}} </p>
			</div>
		</div>
	</div>

	<div class="me-3 ms-3">
		<span class="text-uppercase font-900 font-11 opacity-30">{{'Navigation' | translate}}</span>
		<div class="list-group list-custom-small list-icon-0">
			<a routerLink="/{{congressId}}/user-settings" (click)="closeMenu()">
				<i class="fa font-14 fa-file color-brown-dark"></i>
				<span> {{'MyAccount' | translate}} </span>
				<i class="fa fa-angle-right"></i>
			</a>
			<a routerLink="/{{congressId}}/network" (click)="closeMenu()">
				<i class="fa font-14 fa-star color-yellow-dark"></i>
				<span> {{'MyNetwork' | translate}} </span>
				<i class="fa fa-angle-right"></i>
			</a>
			<a routerLink="/{{congressId}}/chat-history" (click)="closeMenu()" *ngIf="congress?.config?.show_interactions == 1">
				<i class="fa font-14 fa-comment color-blue-dark"></i>
				<span> {{'MyChats' | translate}} </span>
				<i class="fa fa-angle-right"></i>
			</a>
			<a (click)="showQRCode()">
				<i class="fa font-14 fa-qrcode color-green-dark"></i>
				<span> {{'MyQRCode' | translate}} </span>
				<i class="fa fa-angle-right"></i>
			</a>
			<a routerLink="/{{congressId}}/list-accesses" (click)="closeMenu()">
				<i class="fa fa-list color-blue-dark"></i>
				<span> {{'Programme' | translate}} </span>
				<i class="fa fa-angle-right"></i>
			</a>
			<a routerLink="/{{congressId}}/attestation" (click)="closeMenu()">
				<i class="fa font-14 fa-solid fa-download color-blue-dark"></i>
				<span> {{'Attestations' | translate}} </span>
				<i class="fa fa-angle-right"></i>
			</a>
		</div>
	</div>
	<div class="me-3 ms-3 mt-4 pt-2">
		<span class="text-uppercase font-900 font-11 opacity-30">{{'AccountSettings' | translate}}</span>
		<div class="list-group list-custom-small list-icon-0">
			<a data-toggle-theme="" data-trigger-switch="switch-dark2-mode">
				<i class="fa font-12 fa-moon color-yellow-dark rounded-s"></i>
				<span>{{'DarkMode' | translate }}</span>
				<div class="custom-control scale-switch ios-switch">
					<input (click)="darkMode()" data-toggle-theme="" type="checkbox" class="ios-input" id="switch-dark2-mode">
					<label class="custom-control-label" for="switch-dark2-mode"></label>
				</div>
				<i class="fa fa-angle-right"></i>
			</a>
			<a (click)="logout()">
				<i class="fa fa-sign-out" aria-hidden="true"></i>
				<span>{{'Logout' | translate }}</span>
				<i class="fa fa-angle-right"></i>
			</a>
		</div>
	</div>
</div>

<app-user-qr-code-modal></app-user-qr-code-modal>
