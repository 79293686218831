import { Submission } from '../../models/Submission';
import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-item-abstract',
  templateUrl: './item-abstract.component.html',
  styleUrls: ['./item-abstract.component.css']
})
export class ItemAbstractComponent implements OnInit {
  @Input() abstract: Submission;

  constructor() { }

  ngOnInit(): void {
  }

}
