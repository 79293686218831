import { MeetingEvaluation } from "./meetingEvaluation";
import { User } from "./user";

export class Meeting {
    meeting_id: number;
    name: string;
    start_date: Date;
    end_date: Date;
    user_meeting: UserMeeting;
    meeting_evaluation : MeetingEvaluation[] ; 
    endDate: Date;
    participantName: string;
    chatInfo: string;
    showQRBtn: boolean;
    is_organizer_present: number;
}

export class UserMeeting {
    user_meeting_id: number;
    meeting_id: number;
    user_sender_id: number;
    user_received_id: number;
    user_canceler: number;
    status: number;
    participant: User;
    organizer: User;
    is_participant_present: number;
}