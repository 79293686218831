<div routerLink="/{{congressId}}/user-profile/{{accessSpeaker?.user_id}}" >
    <span class="badge bg-red-dark position-absolute font-8 font-400 pt-1">
        {{ (isChair ? 'Moderateur' :'Speaker') | translate}}
    </span>
    <img   *ngIf="accessSpeaker?.profile_img" src="{{filesUrl + accessSpeaker.profile_img.path}}"
        class="rounded-xl bg-green-dark" width="60">
    <img  *ngIf="!accessSpeaker?.profile_img && accessSpeaker?.gender==2" src='../../../assets/images/avatars/10s.png'
        class="rounded-xl bg-green-dark" width="60">
    <img  *ngIf="!accessSpeaker?.profile_img && accessSpeaker?.gender!=2" src='../../../assets/images/avatars/2s.png'
        class="rounded-xl bg-magenta-dark" width="60">
    <h6 class="font-14 mb-3">
        {{accessSpeaker?.first_name + ' ' + accessSpeaker?.last_name }}
    </h6>
</div>