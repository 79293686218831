import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { GenericService } from "./generic.service";

@Injectable({
  providedIn: 'root'
})
export class DNSService extends GenericService {
  constructor(private http: HttpClient) {
    super();
  }

  getDNSByUrl(search) {
    const url = environment.baseUrl + '/dns';
    return this.http.get(url, {
      params : {
        url : search
      }
    });
  }
}