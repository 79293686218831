import { Congress } from './../../models/congress';
import { UserService } from './../../services/user.service';
import { environment } from 'src/environments/environment';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Stand } from '../../models/stand';
import { StandService } from '../../services/stand.service';
import { StorageService } from '../../services/storage.service';
import { User } from '../../models/user';
import { GSTag } from '../../models/gstag';
import { STag } from '../../models/stag';
import { Subscription } from 'rxjs';
import { FormInput, FormInputValue } from '../../models/formInput';
import { Utils } from '../../utils';
import { CongressService } from '../../services/congress.service';
import { TranslateService } from '@ngx-translate/core';
import { Country } from '../../models/Country';

declare var jQuery: any;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit, AfterViewInit, OnDestroy {
  search: string = '';
  page: number = 1;
  perPage: number = 6;
  congressId: string;
  countries: Country[] = [];
  country: Country = new Country();
  stands: Stand[] = [];
  notEmptyPost: boolean = true;
  notscrolly: boolean = true;
  startTyping: boolean = false;
  filesUrl = environment.filesUrl;
  @Output() loadNextPage: EventEmitter<boolean> = new EventEmitter();
  @Input() withUsers: boolean = false;
  users: User [] = [];
  usersPage: number = 1;
  notEmptyUsersPost: boolean = true;
  notscrollyUsers: boolean = true;
  gTags: GSTag[] = [];
  gstags: GSTag[];
  selectedTags: STag[] = [];
  sTags: STag[] = [];
  sTag: string = "";
  searching: boolean = false ;
  busy: Subscription;
  showNothingFound: boolean = false ;
  usersReady: boolean = false;
  fromNetworking:boolean = false;
  filterValues: FormInputValue[]=[];
  formInput: FormInput[]=[];
  lang: string;
  filterKey: number  = null;
  filterKeys: []  = [];
  congress: Congress = new Congress();
  searchCountries:boolean;
  isSelectCountry: string ;


  constructor(private standService: StandService,
    private storageService: StorageService,
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private congressService: CongressService,
    private translate: TranslateService) {
      
    this.congressId = this.storageService.read(StorageService.CONGRESS_ID);
    const url = window.location.href.toLowerCase();
    if (url.includes('b2b')) {
      this.fromNetworking = true;
    }
    this.lang = this.translate.currentLang;
  }

  ngOnInit(): void {  
    if (this.storageService.isExist(StorageService.SearchValue)) {
      this.search = this.storageService.read(StorageService.SearchValue);
    }
    if (this.storageService.isExist(StorageService.Country)) {
      this.isSelectCountry = this.storageService.read(StorageService.Country);
    }
    if (this.storageService.isExist(StorageService.Question)) {
      this.filterKeys = this.storageService.read(StorageService.Question);
    }
    if (this.fromNetworking) {
      this.getAllUsers();
      this.getFilterValues();
    } else {
      this.getGStags();
      this.getStags();
    }
    this.getCongressById();
    this.getAllCountries(); 
    
  }

  ngAfterViewInit() {
    this.searchAfterTyping();
  }

  searchAfterTyping() {
    const baseContext = this;
    var typingTimer;
    var doneTypingInterval = 500;
    var $input = this.fromNetworking ? jQuery('#search-users-networking') : this.withUsers ? jQuery('#search-users-stand') : jQuery('#search-input');

    $input.on('keyup', function () {
      clearTimeout(typingTimer);
      if (baseContext.search != '') {
        typingTimer = setTimeout(doneTyping, doneTypingInterval);
      }
    });
    $input.on('keydown', function () {
      clearTimeout(typingTimer);
      baseContext.startTyping = true;
    });
    function doneTyping() 
    {     
      if (baseContext.startTyping)
       {        
        if (!baseContext.fromNetworking) {
          baseContext.getAllStands();
          if (baseContext.withUsers) {
            baseContext.getAllUsers();
          }
        } else {
          baseContext.getAllUsers();
        }
      }
    }
  }

  enableRemove() {
    if (this.search == '') {
      this.removeSearch();
    } else {
      this.showClearSearch();
    }
  }

  removeSearch() {
    this.search = ''
    this.showClearSearch(true);
    this.showNothingFound = false; 
    this.showListStands();
    this.showSearchStandsResults(true);
    this.showListUsers(true);
    this.loadNextPage.emit(true);
    if (this.fromNetworking) {
      this.getAllUsers();
    }
  }

  getAllStands() {
    this.page = 1;
    this.notEmptyPost = true;
    this.notscrolly = true;
    this.busy=this.standService.getStands(this.congressId, this.page, this.perPage, this.search , this.sTag).subscribe((data: any) => {
        this.stands = data.data;
        this.loadNextPage.emit(false);
        if (this.stands.length > 0) {
          this.showNothingFound = false;
          this.showListStands(true);
          this.showSearchStandsResults();
        } else {
          this.showListStands(true);
          this.showSearchStandsResults(true);
        }
        if (this.users.length == 0 && this.stands.length == 0) {
          this.showNothingFound = true;
        }
    })
  }

  onScroll() {
    if (this.notscrolly && this.notEmptyPost && !this.fromNetworking) {
      this.spinner.show();
      this.notscrolly = false;
      this.loadNextPost();
    }
    if (this.withUsers || this.fromNetworking) {
      if (this.notscrollyUsers && this.notEmptyUsersPost) {
        this.spinner.show();
        this.notscrollyUsers = false;
        this.getRestOfUsers();
      }
    }
  }

  loadNextPost() {
    this.spinner.show();
    this.page += 1;
    this.standService.getStands(this.congressId, this.page, this.perPage, this.search , this.sTag).subscribe((data: any) => {
      if (data.data.length === 0) {
        this.notEmptyPost = false;
      }
      this.paginating(data.data);
      this.spinner.hide();
      this.notscrolly = true;
    }, error =>
      this.spinner.hide()
    );
  }

  paginating(data) {
    for (const stand of data) {
      this.stands.push(stand);
    }
  }

  showClearSearch(hide = false) {
    var clearSearch = document.querySelectorAll('.clear-search')[0];
    hide ? clearSearch.classList.add('disabled') : clearSearch.classList.remove('disabled')
  }

  showListStands(hide = false) {
    var standsList = document.querySelectorAll('.list-stands')[0];
    if (standsList) {
      hide ? standsList.classList.add('disabled') : standsList.classList.remove('disabled');
    }
    this.searching = true ;
  }

  showSearchStandsResults(hide = false) {
    var searchStandList = document.querySelectorAll('.search-stand-results')[0];
    if (searchStandList) {
      hide ? searchStandList.classList.add('disabled-search-list') : searchStandList.classList.remove('disabled-search-list');
    }
  }

  showListUsers(hide = false) {
    var usersList = document.getElementById('users-list');
    if (usersList) {
      hide ? usersList.classList.add('disabled') : usersList.classList.remove('disabled');
    }
  }

  getAllUsers() {
    this.usersPage = 1;
    this.notEmptyUsersPost = true;
    this.notscrollyUsers = true;
    this.usersReady = false;

    this.busy=this.userService.getAllUsersByCongress(this.congressId, this.usersPage, this.perPage, this.search , this.filterKeys,this.isSelectCountry).subscribe((data: any) => {
      this.users = data.data;
      this.usersReady = true;
      if (this.users.length > 0) {
        this.showNothingFound = false;
        this.showListUsers();
      } else {
        if (this.stands.length == 0) {
          this.showNothingFound = true
        } else {
          this.showNothingFound = false;
        }
        this.showListUsers(true);
      }
    });
    this.searching = true ;
  }

  getRestOfUsers() {
    this.spinner.show();
    this.usersPage += 1;
    this.busy=this.userService.getAllUsersByCongress(this.congressId, this.usersPage, this.perPage, this.search, this.filterKeys,this.isSelectCountry).subscribe((data: any) => {
      if (data.data.length === 0) {
        this.notEmptyUsersPost = false;
      }
      this.paginatingUsers(data.data);
      this.spinner.hide();
      this.notscrollyUsers = true;
    }, error =>
      this.spinner.hide()
      
    );
  }

  paginatingUsers(data) {
    for (const user of data) {
      this.users.push(user);
    }
  }

  getStags() {
       this.standService.getTags(this.congressId).subscribe((data: STag[]) => {
        this.sTags = data;
        this.selectedTags = this.sTags;
        this.spinner.hide();
      });
  }

  getGStags() {
      this.standService.getGTags(this.congressId).subscribe((data: GSTag[]) => {
        this.gTags = data;
        this.spinner.hide();
      });
  }

  filterStagsByGStagsId(e) {
   let gstagId = e.target.value;
     if (gstagId  && gstagId != "null") {
      this.selectedTags = this.sTags.filter((e) => e.gstag_id == gstagId);
    } else {
      this.selectedTags = this.sTags;
    } 
  }

  getTagId(e){
    this.sTag = e.target.value;
    this.getAllStands();
  }

  getFilterValues() {
    this.busy = this.congressService.getFilterValues(this.congressId).subscribe((res: any) => {
      this.formInput = res;
      this.formInput.forEach(e => {
        this.filterValues = e.values;
        
      });
    });
  }

  getValueTranslate(columnName: string) {
    return Utils.getValueTranslate(this.lang, this.formInput[0], columnName);
  }

  filterBy(e) {
    this.getIdByKey(e);
  }

  getIdByKey(key) {
    if (key == 'null') {
      this.filterKey = key;
      this.getAllUsers();
    } else {
      this.getAllUsers();
    }
  }

  hasPublicLabel() {
    return this.filterKeys.length == 0 && (this.formInput[0]?.public_label != null || this.formInput[0]?.public_label_en != null)
  }

  getCongressById() {
    this.busy = this.congressService.getCongressDetailsById(this.congressId).subscribe((data: any) => {
      this.congress = data;
      this.searchCountries = this.congress.config.search_countries == 1 ? true : false;
    });
  }

  private getAllCountries() {
    this.busy = this.congressService.getAllCountries(this.congressId).subscribe((data: Country[]) => {
      this.countries = data;
      let noCountry = new Country();
      noCountry.name = 'Choose a country';
      noCountry.name_fr = 'Choisissez un pays';
      noCountry.alpha3code = 'null';
      this.countries.unshift(noCountry);
      if (this.storageService.isExist(StorageService.Country)) {
        const that = this;
        setTimeout(function () {
          jQuery(".select-countries").val(that.isSelectCountry).change();
        }, 1000);
      }
    });
  }

  FilterCountry(e) {
    this.isSelectCountry = e.target.value;
    this.getAllUsers();   
  }

  ngOnDestroy() {
    this.search!= '' ? this.storageService.write(StorageService.SearchValue, this.search) : this.storageService.remove(StorageService.SearchValue);
    this.filterKeys.length > 0 ? this.storageService.write(StorageService.Question, this.filterKeys) : this.storageService.remove(StorageService.Question);
    this.isSelectCountry ? this.storageService.write(StorageService.Country, this.isSelectCountry) : this.storageService.remove(StorageService.Country);
  }
}
