<app-history-search (isSearch)="handleIsSearch($event)"></app-history-search>
<app-default-users *ngIf="!isSearch" [typeData]="'user'" [withStand] = "false" ></app-default-users>

<div  *ngIf="!isSearch" class="page-content" style="margin-top: 20px;">
  <div class=" card card-style  mb-0" *ngIf="userRecieverIds.length > 0">
    <div class="content mb-0">
      <div *ngFor="let userId of userRecieverIds; let i = index">
        <div [ngClass]="{ 'pb-3' : i != userRecieverIds.length - 1 }">
          <app-item-user-history [chatId]="chatId" [userSender]="userSender" [recieverId]="userId"
            [congressId]="congressId" [isLast]="i == userRecieverIds.length - 1">
          </app-item-user-history>
        </div>
      </div>
    </div>
  </div>
</div>