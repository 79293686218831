<div class="modal fade" id="{{modal_id}}" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
  [ngBusy]="busy && userBusy">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="menu-title">
        <h1>{{'Schedulemeet'|translate }} </h1>
        <a style="float: right; margin: -5px;" (click)="closemodal()" class="close-menu"><i class="fa fa-times"></i></a>
      </div>
      <div class="divider divider-margins mb-4"></div>
      <div class="modal-body">
        <div class="input-style input-style-always-active has-borders no-icon mb-4">
          <input style="width: 100%;flex-flow: row-reverse;" type="date" required class="form-control validate-text"
            [(ngModel)]="meetingModel.dateStart" (change)="setEndDate($event)" [ngClass]="{'is-invalid': submitted && !meetingModel.dateStart}"
               min={{meetingModel.congressAvailableDates[0]}} max={{meetingModel.congressAvailableDates[meetingModel.congressAvailableDates.length-1]}}>
          <label for="form612345" class="color-blue-dark"> {{'MeetStart'|translate }}</label>
          <i class="fa fa-check disabled valid me-4 pe-3 font-12 color-green-dark"></i>
          <i class="fa fa-check disabled invalid me-4 pe-3 font-12 color-red-dark"></i>
        </div>
        <div class="input-style input-style-always-active has-borders no-icon mb-4">
          <select class="form-control" [(ngModel)]="meetingModel.timeStart" (change)="setEndTime($event)">
            <option value="null">
              {{'ChooseTime' | translate}}
            </option>
            <option *ngFor="let option of meetingModel.congessAvailableMeetingTimes;let i=index" [disabled]="disabledTime[i]" value="{{option}}">
              {{option}}
            </option>
          </select>
          <label for="form612345" class="color-blue-dark">{{'MeetStartTime'|translate }}</label>
          <i class="fa fa-check disabled valid me-4 pe-3 font-12 color-green-dark"></i>
          <i class="fa fa-check disabled invalid me-4 pe-3 font-12 color-red-dark"></i>
        </div>
        <div class="input-style input-style-always-active has-borders no-icon mb-4" *ngIf="congress?.config?.show_meesage_field == 1">
          <label for="form59876" class="color-blue-dark"> {{'Message'|translate }}</label>
          <textarea type="name" class="form-control validate-name" [(ngModel)]="meeting.name"
            placeholder=" {{'Message'|translate }}">
          </textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button id="sendRequest{{modal_id}}" class="btn btn-sm rounded-s shadow-l bg-highlight btn-center-m text-uppercase font-900"
          (click)="send()" style="width: -webkit-fill-available;">
          {{'Send'|translate }}
      </button>
      </div>
    </div>
  </div>
</div>

<app-error-message [message]="message" [type]="type" [modalId]="errorMessageId" (closeModal)="colseErrorMessageModal()"></app-error-message>