<div class=" header-clear-medium">
    <div class="me-3 ms-3">
        <div class="search-box bg-theme rounded-m shadow-l border-0">
            <i class="fa fa-search"></i>
            <input type="text" class="border-0" id="search-users" [(ngModel)]="search" (ngModelChange)="enableRemove()"
                placeholder="{{ 'Searching' | translate }}" data-search>
            <a class="clear-search disabled mt-0" (click)="removeSearch()"><i
                    class="fa fa-times color-red-dark"></i></a>
        </div>
    </div>
</div>
<div [ngBusy]="busy" class="page-content" style="margin-top: 5px; padding-bottom: 5px; margin-bottom: 70px;" *ngIf="search != '' ">
    <div class=" card card-style  mb-0" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
      <div class="mb-0" style="margin-top: 0 !important; margin-bottom: 0 !important;">
        <div *ngFor="let user of users; let i = index" style="padding: 16px 0 0 0;">     
            <app-item-user-history [isSearch]="true" [userSender]="userSender" [historyReceiver]="user"
                [congressId]="congressId"></app-item-user-history>
        </div>
      </div>
    </div>
</div>
<app-nothing-found *ngIf="showNothingFound"></app-nothing-found>