import { Subscription } from 'rxjs';
import { Component, OnInit, Output, EventEmitter, } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/app/shared/models/user';
import { StorageService } from 'src/app/shared/services/storage.service';
import { UserService } from 'src/app/shared/services/user.service';

declare var jQuery: any;
@Component({
  selector: 'app-history-search',
  templateUrl: './history-search.component.html',
  styleUrls: ['./history-search.component.css']
})
export class HistorySearchComponent implements OnInit {
  search: string = '';
  perPage: number = 6;
  congressId: string;
  users: User[] = [];
  usersPage: number = 1;
  notEmptyUsersPost: boolean = true;
  notscrollyUsers: boolean = true;
  @Output() loadNextPage: EventEmitter<boolean> = new EventEmitter();
  userSender: User = new User();
  @Output() isSearch: EventEmitter<boolean> = new EventEmitter();
  showNothingFound: boolean = false;
  busy: Subscription;

  constructor(private storageService: StorageService, 
    private userService: UserService,
    private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.userSender = this.storageService.read(StorageService.USER_KEY);
    this.congressId = this.storageService.read(StorageService.CONGRESS_ID);
  }

  ngAfterViewInit() {
    this.searchAfterTyping();
  }

  searchAfterTyping() {
    const baseContext = this;
    var typingTimer;
    var doneTypingInterval = 500;
    var $input = jQuery('#search-users');

    $input.on('keyup', function () {
      clearTimeout(typingTimer);
      if (baseContext.search != '') {
        this.isSearch = true;
        typingTimer = setTimeout(doneTyping, doneTypingInterval);
      }
    });
    $input.on('keydown', function () {
      clearTimeout(typingTimer);
    });
    function doneTyping() {
      baseContext.getAllUsers();
    }
  }

  enableRemove() {
    if (this.search == '') {
      this.showClearSearch(true);
      this.showNothingFound = false;
      this.showListUsers(true);
      this.users = [];
      this.loadNextPage.emit(true);
    } else {
      this.showClearSearch();
    }
  }

  removeSearch() {
    this.search = ''
    this.showClearSearch(true);
    this.showNothingFound = false;
    this.showListUsers(true);
    this.users = [];
    this.loadNextPage.emit(true);
  }

  showClearSearch(hide = false) {
    this.isSearch.emit(!hide);
    var clearSearch = document.querySelectorAll('.clear-search')[0];
    hide ? clearSearch.classList.add('disabled') : clearSearch.classList.remove('disabled')
  }

  showListUsers(hide = false) {
    var usersList = document.getElementById('users-list');
    if (usersList) {
      hide ? usersList.classList.add('disabled') : usersList.classList.remove('disabled');
      hide ? this.users = [] : null;    }
  }

  getAllUsers() {
    this.usersPage = 1;
    this.notEmptyUsersPost = true;
    this.notscrollyUsers = true;
    this.users = [];
    this.userService.getAllUsersByCongress(this.congressId, this.usersPage, this.perPage, this.search, null).subscribe((data: any) => {
      this.users = data.data;
      if (this.users.length > 0) {
        this.showNothingFound = false;
        this.showListUsers();
      }else {
        this.showNothingFound = true;
      }
    });
  }

  onScroll() {
      if (this.notscrollyUsers && this.notEmptyUsersPost) {
        this.spinner.show();
        this.notscrollyUsers = false;
        this.getRestOfUsers();
      }
  }

  getRestOfUsers() {
    this.spinner.show();
    this.usersPage += 1;
    this.busy = this.userService.getAllUsersByCongress(this.congressId, this.usersPage, this.perPage, this.search, null).subscribe((data: any) => {
      if (data.data.length === 0) {
        this.notEmptyUsersPost = false;
      }
      this.paginatingUsers(data.data);
      this.spinner.hide();
      this.notscrollyUsers = true;
    }, error =>
      this.spinner.hide()
    );
  }

  paginatingUsers(data) {
    for (const user of data) {
      this.users.push(user);
    }
  }
}
