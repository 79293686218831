import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GenericService } from './generic.service';

@Injectable({
    providedIn: 'root'
})

export class StandService extends GenericService {

    constructor(private http: HttpClient) {
        super();
    }

    getStands(congressId: string, page: number, perPage: number, search: string = '' , stag_id: string = null) {
        const url = environment.baseUrl + '/user/congress/' + congressId + `/stands?page=${page}&perPage=${perPage}&search=${search}&stag_id=${stag_id}`;
        return this.http.get(url);
    }

    getStandById(congressId: string, standId: string) {
        const url = environment.baseUrl + '/congress/' + congressId + '/stand/getStandById/' + standId;
        return this.http.get(url);
    }

    getTags(congressId: string) {
        const url = environment.baseUrl + '/congress/' + congressId + '/stags/stand-tag-list' ;
        return this.http.get(url);
    }

    getGTags(congressId: string) {
        const url = environment.baseUrl + '/congress/' + congressId + '/gstags/stand-gtag-list' ;
        return this.http.get(url);
      } 
      
    getRandomStands(congressId: string) {
        const url = environment.baseUrl + '/user/congress/' + congressId + `/random-stands-pwa`;
        return this.http.get(url);
    }  
    getPlanStands(congressId: string) {
        const url = environment.baseUrl + '/congress/' + congressId + `/plan-stand/`;
        return this.http.get(url);
    } 
}