export class QuestionQuiz {
    title: string;
    duration: number;
    status = 0;
    isMultiple = 1;
    isReponses: boolean;
    quizId: string;
    responses: ResponseQuestion[] = [];
    questionId: number;
    key: string;
    totalVotes: number;
}

export class ResponseQuestion {
    label: string;
    isCorrect = 0;
    responseId: number;
    note : number;
    votes = 0;
}

export class Responsible {
    userId: string;
}

export class FirebaseUser {
    userId : number;
    userName: string;
    responses: any[] = [];
}

export class FirebaseData {
    key: string;
    payload: any;
}