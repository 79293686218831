
import { Component, Input, OnInit } from '@angular/core';
import { Access } from '../../models/access';
import { StorageService } from '../../services/storage.service';
import { Utils } from '../../utils';


@Component({
  selector: 'app-item-access',
  templateUrl: './item-access.component.html',
  styleUrls: ['./item-access.component.css']
})
export class ItemAccessComponent implements OnInit {
  congressId: number
  @Input() access: Access;
  constructor(private storageService: StorageService) {
    this.congressId = this.storageService.read(StorageService.CONGRESS_ID);
  }

  ngOnInit(): void {
  }

  getAccesseTime(accesseDate) {
    return Utils.getTimeFromDate(accesseDate);
  }

  getAccesseDate(accesseDate) {
    return Utils.convertDateToFrenchFormat(accesseDate);
  }
}
