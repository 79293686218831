import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment.dev';
import { User } from '../../models/user';
import { ChatService } from '../../services/chat.service';
import { UserService } from '../../services/user.service';
import { Utils } from '../../utils';
import * as moment from 'moment';

declare var jQuery: any;

@Component({
  selector: 'app-item-user-history',
  templateUrl: './item-user-history.component.html',
  styleUrls: ['./item-user-history.component.css']
})
export class ItemUserHistoryComponent implements OnInit {
  lastMessage: any;
  @Input() chatId: string;
  chatName: string;
  @Input() userSender: User = new User();
  userReciever: User = new User();
  @Input() recieverId: number
  busy: Subscription;
  @Input() congressId: string;
  @Input() isLast: boolean;
  nbunreadMessages: number = 0;
  lastMessageTimeStamp : string;
  history: any[] = [];
  filesUrl = environment.filesUrl;
  receiverFirstLetters: string;
  @Input() isSearch: boolean;
  @Input() historyReceiver: User = null;


  constructor(private chatService: ChatService,
    private userService: UserService,
    private route: ActivatedRoute,private datepipe: DatePipe) {
  }

  ngOnInit(): void {
    if (!this.historyReceiver) {
      this.getUserById();
    } else {
      this.userReciever = this.historyReceiver; this.receiverFirstLetters = Utils.getUserFirstLetters(this.userReciever);
    }
    this.chatName = Utils.getPrivateChatName(this.userSender.user_id, this.recieverId);
    if (this.chatId) {
      this.getAllMessages(this.chatName);
    }
  }


  getUserById() {
    this.busy = this.userService.getUserById(this.recieverId).subscribe((data: User) => {
      this.userReciever = data;
      this.receiverFirstLetters = Utils.getUserFirstLetters(this.userReciever);
    });
  }

  getAllMessages(chatName) {
    this.chatService.getAllMessages(this.chatId, chatName).subscribe((actions: any[]) => {
      this.filterMessages(actions);
    });
  }

  filterMessages(actions) {
    this.lastMessage = actions[actions.length - 1].payload.val();
    this.lastMessageTimeStamp = Utils.timeSince(this.lastMessage.timeStamp) + ' ago';
    this.getUnreadMessages();
  }

  getUnreadMessages() {
    this.chatService.getHistory(this.chatId, this.recieverId).subscribe((actions: any[]) => {
      actions.forEach(action => {
        if (action.payload.val().user_id == this.userSender.user_id) {
          this.nbunreadMessages = action.payload.val().unreadMessages;
          return;
        }
      }
      );
    });
  }
}
