import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/shared/services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class SharedService  extends GenericService {
    public title = new BehaviorSubject('Title');

    constructor(private http: HttpClient, private storageService: StorageService) {
        super();
    }

    setTitle(title: string) {
        this.title.next(title);
    }

    getAllCountries(congressId = null) {
        const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
        const url = environment.baseUrl + "/countries?congressId=" + congressId;
    
        return this.http.get(url, {
          headers: headers
        });
      }
}