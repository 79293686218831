import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { StorageService } from './storage.service';
import { catchError } from 'rxjs/operators';
import { FireBaseKeyRequest } from '../models/FireBaseKeyRequest';
@Injectable()
export class MessagingService extends GenericService {
  currentMessage = new BehaviorSubject(null);
  currentToken = new BehaviorSubject(null);
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private http: HttpClient,
    private storageService: StorageService
  ) {
    super();
  }
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.currentToken.next(token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload:any) => {
        console.log(payload)
        this.currentMessage.next(payload);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  saveToken(congress_id: string, body: FireBaseKeyRequest) {
    const url =
      environment.baseUrl +
      '/user-app/send-firebase-key/' +
      congress_id +
      '?deleted=false';
    const headers = this.headers.set(
      'Authorization',
      'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY)
    );
    return this.http
      .post<any>(url, body, {
        headers: headers,
      })
      .pipe(catchError(this.handleErrors));
  }
}
