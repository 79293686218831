import { ChatService } from './shared/services/chat.service';
import { UserService } from './shared/services/user.service';
import { HomeComponent } from './home/home.component';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BottomNavigationComponent } from './bottom-navigation/bottom-navigation.component';
import { HeaderBarComponent } from './header-bar/header-bar.component';
import { SharedService } from './shared/services/shared.service';
import { TranslateModule, TranslateStore, TranslateLoader } from '@ngx-translate/core';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StorageService } from './shared/services/storage.service';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgBusyModule } from 'ng-busy';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { StandService } from './shared/services/stand.service';
import { FormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { ApmErrorHandler, ApmModule, ApmService } from '@elastic/apm-rum-angular';
import { MessagingService } from './shared/services/messaging.service';
import { TokenInterceptor } from './shared/interceptor/token.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    BottomNavigationComponent,
    HeaderBarComponent,
    HomeComponent,
    GlobalSearchComponent,
    ],
    
  imports: [
    BrowserModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
        
      }
    }),
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    SharedModule,
    ApmModule,
    NgBusyModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    ApmService,
    SharedService,
    TranslateStore,
    StorageService,
    UserService,
    StandService,
    AngularFireDatabase,
    ChatService,
    MessagingService,
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler
    },
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(service: ApmService) {
    // API is exposed through this apm instance
    /* TODO desactivate
    const apm = service.init({
      serviceName: 'eventizer-participant-pwa-' + environment.environment,
      serverUrl: environment.elasticRUMUrl
    });

    apm.setUserContext({
      'username': 'eventizer-' + environment.environment,
      'id': environment.environment
    });
    */
  }
}
