export const environment = {
    production: false,
    url: 'https://dev.participant.eventizer.io/#',
    baseUrl: 'https://dev.api.eventizer.io/api',
    filesUrl: 'https://eventizer-dev.s3.eu-west-3.amazonaws.com/',
    firebase: {
        apiKey: 'AIzaSyByyz8HRzYLQHK3ttyGd40l88fsMm0GXAo',
        authDomain: 'eventizer-dev.firebaseapp.com',
        databaseURL: 'https://eventizer-dev.firebaseio.com',
        projectId: 'eventizer',
        storageBucket: 'eventizer-dev.appspot.com',
        messagingSenderId: '1060423498159',
        appId: '1:1060423498159:web:34411e703b2643aa63575a',
        measurementId: 'G-KSJDWZYJ5X'
      },
  googleAnalyticsId: "G-YG0PB37FQ7",
  elasticRUMUrl: 'https://apm.prod.master.vayetek.com',
  environment: 'dev',
  agoraAPPID  : 'a6c74d414176481c83a31e5927539abc',
  badgeServiceUrl: "https://congress-file-generater.vayetek.com",
};