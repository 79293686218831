import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { GenericService } from './generic.service';
import { Congress } from '../models/congress';
import { catchError } from 'rxjs/operators';
import { FormInput } from '../models/formInput';
import { Submission } from '../models/Submission';
import { PwaBtn } from '../models/PwaBtn';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CongressService extends GenericService {

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }

  getCongressDetailsById(congressId: string) {
    const url = environment.baseUrl + '/congress/' + congressId + '/details';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<Congress>(url, {
      headers: headers
    }).pipe(catchError(this.handleErrors));
  }

  getCongressMinById(congressId: number,cache:boolean=false) {
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/congress/" + congressId + "/min?cache=" + cache;

    return this.http.get(url, {
      headers: headers
    }) as Observable<Congress>;
  }

  getNumberOfParticipants(congressId: string) {
    const url = environment.baseUrl + '/user/congress/' + congressId + '/participants-number';
    return this.http.get<number>(url).pipe(catchError(this.handleErrors));
  }

  getFilterValues(congressId: string) {
    const url = environment.baseUrl + '/user/congress/' + congressId + '/filter-values';
    return this.http.get<FormInput>(url).pipe(catchError(this.handleErrors));
  }

  getIdByKey(congressId: string, formInputId: number, key: string) {
    const url = environment.baseUrl + '/user/congress/' + congressId + '/' + formInputId + '/' + key + '/value-id';
    return this.http.get<FormInput>(url).pipe(catchError(this.handleErrors));
  }


  getSoumissionCongressById(congressId: number, search: string = null, status: number = null, page: number = null, perPage: number = null, offset: number = null, themeId: number = null, typeId: number = null) {
    const url = environment.baseUrl + `/submissions/congress/${congressId}?search=${search}&status=${status}&perPage=${perPage}&offset=${offset}&theme_id=${themeId}&communication_type_id=${typeId}`;
    return this.http.get<Submission[]>(url);
  }
  getThemesCongressById(congressId: number) {
    const url = environment.baseUrl + '/theme/congress/' + congressId;
    return this.http.get(url).pipe(catchError(this.handleErrors));
  }


  getSponsorsByCongress(congressId: number, isSponsor: number) {
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/congress/" + congressId + "/sponsors-grouped?isSponsor=" + isSponsor;
    return this.http.get(url, {
      headers: headers
    })
  }

  getSponsorsByCongressId(congressId: string, isSponsor: number) {
    const url = environment.baseUrl + "/congress/" + congressId + "/sponsors?isSponsor=" + isSponsor;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get<Congress>(url, {
      headers: headers
    });
  }

  getAllAccessByCongress(congressId: number) {
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/access/congress/" + congressId;
    return this.http.get(url, {
      headers: headers
    });
  }

  getManagePwaBtns(congress_id: number) {
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/admin/me/congress/" + congress_id + "/get-pwa-btns";
    return this.http.get<PwaBtn[]>(url, {
      headers: headers
    });
  }

  getAllCountries(congressId = null) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/countries?congressId=" + congressId;
    return this.http.get(url, { headers: headers });
  }

  getAllPwamenuByCongress(congressId: number) {
    const url = environment.baseUrl + "/pwamenu/list-visible/" + congressId;
    return this.http.get(url);
  }

  getConfigLandingPageById(congress_id) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + "/congress/" + congress_id + "/landing-page/get-config";
    return this.http.get(url, { headers: headers});
  }

}