import { StorageService } from 'src/app/shared/services/storage.service';
import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Meeting } from '../models/meeting';
import { MeetingEvaluation } from '../models/meetingEvaluation';

@Injectable({
  providedIn: 'root'
})
export class MeetingService extends GenericService {
  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }

  requestMeeting(meeting: Meeting, user_receive_id: number, congress_Id: number , urlMeeting: string = '') {
    const url = environment.baseUrl + `/meetings/add?congress_id=` + congress_Id + `&user_received_id=` + user_receive_id+`&urlMeeting=` + urlMeeting;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.post(url, meeting, {
      headers: headers,
    });
  }

  getUserMeetingById(user_id: number, congress_id: string, qr_code: string='', status: string = '', perPage: number, page: number) {
    const url = environment.baseUrl + '/meetings/' + congress_id + '?qr_code=' + qr_code + '&user_id=' + user_id + '&status=' + status + '&perPage=' + perPage + '&page=' + page;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get(url, {headers: headers});
  }

  addMeetingEvaluation(meetingEvaluation: MeetingEvaluation) {
    const url = environment.baseUrl + '/meetings/add-evaluation-meeting-PWA';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.post<MeetingEvaluation[]>(url, meetingEvaluation, {
      headers: headers,
    });
  }
  
  getFixTables(congressId: number, page: number, perPage: number, search: string, filterBy: number){
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + `/meetings/` + congressId + `/get-fix-tables?page=${page}&perPage=${perPage}&search=${search}&filterBy=${filterBy}`;
    return this.http.get(url, {
      headers: headers
    })
  }

  updateMeeting(meetingId: Number, status: number, urlMeeting:string) {
    const url = environment.baseUrl + `/meetings/` + meetingId + `/update-status`;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.put(url, {status : status, urlMeeting: urlMeeting}, {
      headers: headers,
    });
  }

  getNumberWaitingMeeting(congressId: string){
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    const url = environment.baseUrl + `/meetings/` + congressId + `/get-number-waiting-meeting`;
    return this.http.get(url, {
      headers: headers
    });
  }

  getMeetingDates(congress_id: number) {
    const url = environment.baseUrl + "/meetings/" + congress_id + "/get-meetings-dates" ;
    return this.http.get(url);
  }

  makeOrganizerPresentByQrCode(meeting_id: number, qr_code: string, urlMeeting) {
    const url = environment.baseUrl + '/meetings/' + meeting_id + '/organizer-presence-qr-code';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.post(url, { is_organizer_present: 1, qr_code: qr_code, urlMeeting: urlMeeting }, { headers: headers });

  }

  makeParticipantPresentByQrCode(meeting_id: number, qr_code: string, urlMeeting) {
    const url = environment.baseUrl + '/meetings/' + meeting_id + '/participant-presence-qr-code';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.post(url, { is_participant_present: 1, qr_code: qr_code, urlMeeting: urlMeeting }, { headers: headers });
  }
    makeOrganizerPresent(meeting_id) {
    const url = environment.baseUrl + '/meetings/' + meeting_id + '/statMeetingOrganizer';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.put<any>(url, { is_organizer_present: 1 }, { headers: headers });
  }

  makeParticipantPresent(meeting_id, user_id) {
    const url = environment.baseUrl + '/meetings/' + meeting_id + '/statMeetingParticipant';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.put<any>(url, { is_participant_present: 1, user_id: user_id }, { headers: headers });
  }

  getEvaluationQuestions(congressId) {
    const url = environment.baseUrl + `/meetings/` + congressId + `/question`;
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.get(url, { headers: headers });
  }

  addMeetingReponse(meetingEvaluation: MeetingEvaluation[], meetingId) {
    const url = environment.baseUrl + '/meetings/' + meetingId + '/add-reponse';
    const headers = this.headers.set('Authorization', 'Bearer ' + this.storageService.read(StorageService.USER_TOKEN_KEY));
    return this.http.post<MeetingEvaluation[]>(url, meetingEvaluation, {
      headers: headers,
    });
  }

}

