import { environment } from './../../../../environments/environment';
import { User } from './../../models/user';
import { Component, Input, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { Utils } from '../../utils';

@Component({
  selector: 'app-item-user',
  templateUrl: './item-user.component.html',
  styleUrls: ['./item-user.component.css']
})
export class ItemUserComponent implements OnInit {
  @Input() user: User;
  filesUrl= environment.filesUrl;
  congressId : number;
  userFirstLetters: string;
  chatInfo: string[] = [];
  @Input() isFixTable: boolean = false;
  
  constructor(private storageService: StorageService) {
    this.congressId = this.storageService.read(StorageService.CONGRESS_ID);
  }

  ngOnInit(): void {
    this.userFirstLetters = Utils.getUserFirstLetters(this.user);
    this.user.last_name = this.user.last_name.toUpperCase();
    this.user.first_name = Utils.capitalizeFirstLetters(this.user.first_name);
    if (this.user.user_congresses[0].chat_info) {
      this.chatInfo = this.user.user_congresses[0].chat_info.split(";")
    }
  }
}
